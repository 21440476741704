import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlogCards = _resolveComponent("BlogCards")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, { gutter: [32,16] }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_BlogCards, {
              title: "Four ways to cheer yourself up on the Next Blue Monday!",
              smalltitle: "BETTING-NEWS",
              date: _ctx.$formatDate(new Date())
            }, null, 8, ["date"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_BlogCards, {
              title: "Which are the Most Popular Roulette Systems?",
              smalltitle: "BETTING-NEWS",
              date: _ctx.$formatDate(new Date())
            }, null, 8, ["date"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { lg: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_BlogCards, {
              title: "How to Properly Use Casino Fibonacci System for now",
              smalltitle: "BETTING-NEWS",
              date: _ctx.$formatDate(new Date())
            }, null, 8, ["date"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}