
import { defineComponent, PropType } from "vue";
import { Thumbnail } from "@/types";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    smalltitle: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
  },
});
