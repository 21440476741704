import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7ff8b185")
const _hoisted_1 = { class: "date" }
const _hoisted_2 = { class: "content d-flex flex-column" }
const _hoisted_3 = { class: "small-title" }
const _hoisted_4 = { class: "title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "news-cards d-flex align-items-end",
    style: _normalizeStyle({
      'background-image':
        'url(' +
        require('../../assets/images/demosimages/article-blog-1.jpeg') +
        ')',
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$formatDate(_ctx.date)), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.smalltitle), 1),
      _createVNode(_component_router_link, { to: "link" }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      })
    ])
  ], 4))
}