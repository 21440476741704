
import { defineComponent } from "vue";

import SectionHomeSlots from "../components/SectionHomeSlots/SectionHomeSlots.vue";
import fetchHomeHeader from "../misc/composition/fetchHomeHeader";
import MainHeader from "../components/MainHeader/MainHeader.vue";
import SubtitleSections from "../components/SubtitleSections/SubtitleSections.vue";
import SectionHomeGridNews from "../components/SectionHomeGridNews/SectionHomeGridNews.vue";
import SectionArticles from "@/components/SectionArticles/SectionArticles.vue";
import SectionNewOnlineCasinos from "@/components/SectionNewOnlineCasinos/SectionNewOnlineCasinos.vue";
import SectionHomeBonuses from "@/components/SectionHomeBonuses/SectionHomeBonuses.vue";
import SectionTips from "@/components/SectionTips/SectionTips.vue";
import Bookmakers from "@/components/Bookmakers/Bookmakers.vue";
import fetchBookmaker from "../misc/composition/fetchBookmakersTable";
export default defineComponent({
  name: "Home",
  components: {
    SectionHomeSlots,
    MainHeader,
    SectionHomeGridNews,
    SubtitleSections,
    SectionArticles,
    SectionNewOnlineCasinos,
    SectionHomeBonuses,
    SectionTips,
    Bookmakers,
  },
  setup() {
    document.title = "Home Bookmakers - Coinflip";

    const { header, loading } = fetchHomeHeader();
    const { bookmaker, loading: bookmakerloading } = fetchBookmaker();
    return { fetchHomeHeader, loading, header, bookmaker, bookmakerloading };
  },
});
