
import { defineComponent,PropType } from "vue";
import BlogCards from "../BlogCards/BlogCards.vue"
export default defineComponent({
  name: "Home",
  components: {
    BlogCards       
  },
   props: {
    items: {
      type: Object as PropType<String[]>,
    },
    title: {
        type:String
    },
  },
});
