import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-116f6855")
const _hoisted_1 = {
  key: 0,
  class: "header"
}
const _hoisted_2 = { class: "container container-slots position-relative" }
const _hoisted_3 = { class: "container-slots-game" }
const _hoisted_4 = { class: "container container-articles" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "section-news container d-flex flex-column position-relative" }
const _hoisted_7 = { class: "container-news-grid" }
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "container" }
const _hoisted_10 = { class: "container-table" }
const _hoisted_11 = { class: "container" }
const _hoisted_12 = { class: "container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_SectionHomeSlots = _resolveComponent("SectionHomeSlots")!
  const _component_SubtitleSections = _resolveComponent("SubtitleSections")!
  const _component_SectionArticles = _resolveComponent("SectionArticles")!
  const _component_SectionNewOnlineCasinos = _resolveComponent("SectionNewOnlineCasinos")!
  const _component_SectionHomeGridNews = _resolveComponent("SectionHomeGridNews")!
  const _component_SectionHomeBonuses = _resolveComponent("SectionHomeBonuses")!
  const _component_Bookmakers = _resolveComponent("Bookmakers")!
  const _component_SectionTips = _resolveComponent("SectionTips")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, null, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: "position-relative main-header",
                  style: _normalizeStyle({
            'background-image':
              'url(' +
              require('../assets/images/demosimages/Home-Bookmakers-BG.jpeg') +
              ')',
          })
                }, [
                  _createVNode(_component_main_header, {
                    class: "content-header",
                    title: "Get a revenue from your casino by joining Coinflip",
                    description: "Nullam turpis lorem, feugiat in augue ac, aliquam vestibulum augue. Nunc rutrum lacinia vestibulum. Integer urna elit.",
                    href: "header.button.href",
                    buttonName: "Join Coinflip"
                  })
                ], 4)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SectionHomeSlots)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SubtitleSections, {
              class: "news-title",
              title: "Sport Betting Articles",
              subtitle: "At Coinflip, we show only the best casinos."
            }),
            _createVNode(_component_SectionArticles)
          ]),
          _createElementVNode("div", {
            class: "section w-100 latest-casinos",
            style: _normalizeStyle({
          'background-image':
            'url(' +
            require('../assets/images/demosimages/background-latest-casinos.jpeg') +
            ')',
        })
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_SubtitleSections, {
                class: "casinos-title",
                title: "Latest Casinos",
                subtitle: "At Coinflip, we show only the best casinos"
              }),
              _createVNode(_component_SectionNewOnlineCasinos)
            ])
          ], 4),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_SubtitleSections, {
              class: "news-title",
              title: "Sport Betting Articles",
              subtitle: "At Coinflip, we show only the best casinos."
            }),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_SectionHomeGridNews)
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "container-bonuses",
            style: _normalizeStyle({
          'background-image':
            'url(' +
            require('../assets/images/demosimages/background-latest-casinos.jpeg') +
            ')',
        })
          }, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_SubtitleSections, {
                class: "casinos-title",
                title: "Latest Casinos",
                subtitle: "At Coinflip, we show only the best casinos"
              }),
              _createVNode(_component_SectionHomeBonuses)
            ])
          ], 4),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_SubtitleSections, {
                class: "casinos-title",
                title: "Bookmakers By Coinflip",
                subtitle: "At Coinflip, we show only the best casinos"
              }),
              _createVNode(_component_Bookmakers, {
                items: _ctx.bookmaker.slice(0, 5)
              }, null, 8, ["items"])
            ])
          ]),
          _createElementVNode("div", {
            class: "container-tips",
            style: _normalizeStyle({
          'background-image':
            'url(' +
            require('../assets/images/demosimages/background-latest-casinos.jpeg') +
            ')',
        })
          }, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_SubtitleSections, {
                class: "news-title",
                title: "Extra Game Tips",
                subtitle: "At Coinflip, we show only the best casinos."
              }),
              _createVNode(_component_SectionTips)
            ])
          ], 4)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}